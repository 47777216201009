import { Controller } from "stimulus";
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ["modal", "topicOptions"];

  connect() {
    this.element
      .querySelector(".modal-header button")
      .addEventListener("click", this.resetForm.bind(this));

    this.element.addEventListener("click", function (e) {
      if (e.target.id === "legal_consultation_attached_file_delete_button") {
        document.querySelector(
          "#legal-consultation-form .file-input-container .input-text"
        ).value = I18n.t(
          "views.committee.sections.legal_consultations.form.attached_file_input_placeholder"
        );
      }
    });

    this._addCustomPlaceHolderToFileUploader();
  }

  showModal() {
    $(this.modalTarget).modal();
  }

  hideModal(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this.modalTarget).modal("hide");
    this.resetForm();
  }

  toggleBoldLabel(event) {
    const optionLabel = event.currentTarget.parentElement.nextElementSibling;

    if (event.currentTarget.checked) {
      optionLabel.classList.add("bold-label");
    } else {
      optionLabel.classList.remove("bold-label");
    }
  }

  checkValidations() {
    const selectedOptions = this._getSelectedTopics();
    const description = this._descriptionInput().value;

    if (this._meetsValidations(selectedOptions, description)) {
      this._getSubmitBtn().enable();
    } else {
      this._getSubmitBtn().disable();
    }
  }

  async createLegalConsultation(e) {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData();
    this._getSelectedTopics().forEach((topicOption) =>
      formData.append("legal_consultation[topics][]", topicOption)
    );
    formData.append(
      "legal_consultation[description]",
      this._descriptionInput().value
    );
    formData.append(
      "legal_consultation[attached_file]",
      this._getAttachedFile()
    );

    await fetch("/legal_consultations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        $(this.modalTarget).modal("hide");
        this._handleResponse(data);
      })
      .finally(() => this.resetForm());
  }

  resetForm() {
    this._getTopicsGroup().map((topic) => {
      topic.checked = false;
      topic.parentElement.nextElementSibling.classList.remove("bold-label");
    });
    this._descriptionInput().value = "";
    if (this._getAttachedFile()) this._deleteAttachedFiles();
    this._getSubmitBtn().disable();
    this._addCustomPlaceHolderToFileUploader();
  }

  _handleResponse(data) {
    if (data.success) {
      triggerNotice(data.message);
      allow_multiple_notices();
    } else {
      triggerAlert(data.message);
      allow_multiple_alerts();
    }
  }

  _meetsValidations(selectedOptions, description) {
    return selectedOptions.length && description.replace(/\s/g, "") !== "";
  }

  _getSelectedTopics() {
    return this._getTopicsGroup()
      .filter((option) => option.checked)
      .map((option) => option.id);
  }

  _getTopicsGroup() {
    return Array.from(
      this.element
        .querySelector("#topic-options")
        .querySelectorAll("input[type=checkbox]")
    );
  }

  _getSubmitBtn() {
    const submitBtn = this.element.querySelector("#submit-btn");

    return {
      enable: () => submitBtn.classList.remove("disabled"),
      disable: () => submitBtn.classList.add("disabled"),
    };
  }

  _descriptionInput() {
    return this.element.querySelector("#_description");
  }

  _getAttachedFile() {
    return this.element.querySelector("#legal_consultation_attached_file")
      ?.files[0];
  }

  _deleteAttachedFiles() {
    this.element
      .querySelector("#legal_consultation_attached_file_delete_button")
      .click();
  }

  _addCustomPlaceHolderToFileUploader() {
    this.element.querySelector(".file-input-container .input-text").value =
      I18n.t(
        "views.committee.sections.legal_consultations.form.attached_file_input_placeholder"
      );
  }
}
